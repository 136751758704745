import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { MyFollowIndex } from '@/api/ceshi_xiugai/whole';
export default {
  data: function data() {
    return {
      //系统教程
      tab: 0,
      loading: true,
      course: this.$route.query.course,
      //系统教程
      radio: 1,
      title: '跟进记录',
      value: '',
      tableData: [],
      Alltableoptions: [],
      total: 0,
      visdisplay: false,
      cname: '',
      //客户名字
      start_time: '',
      end_time: '',
      nextVisit: 0,
      nexttime: '',
      //下次回访时间
      next_follow_stime: '',
      next_follow_etime: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      list_type: 0,
      //全部表格
      followTime: [],
      NumberEs: 2,
      theme: '',
      contactType: '',
      nexttimeshow: false,
      typeoptions: {
        1: '电话',
        2: '微信',
        3: 'QQ',
        4: '面聊'
      },
      resultES: '',
      displaySettings: ['下次回访日期', '执行人'],
      executorshow: false,
      executor_name: ''
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.created();
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    created: function created() {
      this.followTime = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      this.start_time = this.followTime[0].toString().substr(0, 10);
      this.end_time = this.followTime[1].toString().substr(0, 10);
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    GetList: function GetList(page, pagesize) {
      var _this$resultES,
        _MyFollowIndex,
        _this = this;
      this.loading = true;
      this.resultES = (_this$resultES = {
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        type: Number(this.contactType),
        list_type: this.list_type,
        ittnzy: this.Intended,
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultES, "start_follow_time", this.start_follow_time), "end_follow_time", this.end_follow_time), "ittnzy", this.showittnzy), "source_client_id", this.source_client_id), "create_stime", this.create_stime), "create_etime", this.create_etime), "create_realname", this.create_realname), "structure_ids", this.structure_idEs), "create_time_order", this.establish), "follow_time_order", this.finalFollowUp), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultES, "next_time_order", this.nextVisit), "labelpeers", this.labelpeers), "notes", this.remarks), "cname", this.cname));
      MyFollowIndex((_MyFollowIndex = {
        page: String(page),
        pagesize: String(pagesize),
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        type: Number(this.contactType),
        list_type: this.list_type,
        ittnzy: this.Intended,
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_MyFollowIndex, "start_follow_time", this.start_time), "end_follow_time", this.end_time), "ittnzy", this.showittnzy), "source_client_id", this.source_client_id), "create_stime", this.create_stime), "create_etime", this.create_etime), "create_realname", this.create_realname), "structure_ids", this.structure_idEs), "create_time_order", this.establish), "follow_time_order", this.finalFollowUp), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_MyFollowIndex, "next_time_order", this.nextVisit), "labelpeers", this.labelpeers), "notes", this.remarks), "title", this.theme), "cname", this.cname), "username", this.executor_name))).then(function (respomse) {
        _this.loading = false;
        _this.tableData = respomse.data.data;
        _this.query.total = respomse.data.total;
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this2 = this;
      this.NumberEs = Number(0);
      this.query.page = 1;
      if (this.followTime == null) {
        this.start_time = '';
        this.end_time = '';
        this.$nextTick(function () {
          _this2.GetList(_this2.query.page, _this2.query.pagesize);
        });
      } else {
        var s_time = this.followTime[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.followTime[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this2.GetList(_this2.query.page, _this2.query.pagesize);
        });
      }
    },
    nextDateTime: function nextDateTime(item) {
      //回访时间选择
      this.query.page = 1;
      if (this.nexttime == null) {
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else {
        this.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
        this.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
        this.GetList(this.query.page, this.query.pagesize);
      }
    },
    //联系人
    executor: function executor() {
      this.GetList(this.query.page, this.query.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '执行人') {
        this.executorshow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      var _this3 = this;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.nexttime = '';
        this.$nextTick(function () {
          _this3.GetList(_this3.query.page, _this3.query.pagesize);
        });
      } else if (item === '执行人') {
        this.executorshow = false;
        this.executor_name = '';
        this.$nextTick(function () {
          _this3.GetList(_this3.query.page, _this3.query.pagesize);
        });
      }
    },
    goTodetails: function goTodetails(row, val) {
      //客户详情
      var routeUrl = this.$router.resolve({
        path: '/FollowDetail?Cid=' + row.customer_id + '&CData_id=' + row.customer_data_id + '&editRole=1&key=' + val.$index + '&page=' + this.query.page + '&pagesize=' + this.query.pagesize + '&list_type=' + this.list_type
      });
      var asas = JSON.stringify(this.resultES);
      localStorage.setItem('resultES', asas);
      // localStorage.setItem('editStatus', row.edit_status);
      window.open(routeUrl.href, '_blank');
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'next_time') {
        //下次回访日期
        if (column.order == 'ascending') {
          this.nextVisit = '1';
        } else if (column.order == 'descending') {
          this.nextVisit = '2';
        } else {
          this.nextVisit = '0';
        }
      }
      this.GetList(this.query.page, this.query.pagesize);
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this4.GetList(_this4.query.page, _this4.query.pagesize);
      });
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this5.GetList(_this5.query.page, _this5.query.pagesize);
      });
    },
    //主题查询
    handleTheme: function handleTheme() {
      var _this6 = this;
      this.$nextTick(function () {
        _this6.GetList(_this6.query.page, _this6.query.pagesize);
      });
    },
    //查询
    handleFilter: function handleFilter() {
      var _this7 = this;
      this.$nextTick(function () {
        _this7.GetList(_this7.query.page, _this7.query.pagesize);
      });
    },
    handleType: function handleType(val) {
      var _this8 = this;
      this.type = val;
      this.$nextTick(function () {
        _this8.GetList(_this8.query.page, _this8.query.pagesize);
      });
    },
    //选中操作
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
    }
  }
};