var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container Follow" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { margin: "0px 0px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "40px", margin: "20px 0 10px 0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _vm._v(
                          "\n                    主题：\n                    "
                        ),
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            staticStyle: { width: "80%" },
                            attrs: {
                              placeholder: "请输入主题",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleTheme($event)
                              },
                            },
                            model: {
                              value: _vm.theme,
                              callback: function ($$v) {
                                _vm.theme = $$v
                              },
                              expression: "theme",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleTheme },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _vm._v(
                          "\n                    类型：\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "small",
                            },
                            on: { change: _vm.handleType },
                            model: {
                              value: _vm.contactType,
                              callback: function ($$v) {
                                _vm.contactType = $$v
                              },
                              expression: "contactType",
                            },
                          },
                          _vm._l(_vm.typeoptions, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _vm._v(
                          "\n                    跟进时间：\n                    "
                        ),
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            type: "daterange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "timestamp",
                            size: "small",
                            clearable: "",
                            "default-time": ["00:00:00", "23:59:59"],
                          },
                          on: { change: _vm.DataTime },
                          model: {
                            value: _vm.followTime,
                            callback: function ($$v) {
                              _vm.followTime = $$v
                            },
                            expression: "followTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 5 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              placeholder: "请输入电话，名称查询客户",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleFilter($event)
                              },
                            },
                            model: {
                              value: _vm.cname,
                              callback: function ($$v) {
                                _vm.cname = $$v
                              },
                              expression: "cname",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleFilter },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.executorshow,
                              expression: "executorshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("执行人:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请搜索执行人",
                                size: "small",
                              },
                              on: { change: _vm.executor },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.executor($event)
                                },
                              },
                              model: {
                                value: _vm.executor_name,
                                callback: function ($$v) {
                                  _vm.executor_name = $$v
                                },
                                expression: "executor_name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.executor },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("执行人")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nexttimeshow,
                              expression: "nexttimeshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nameTitle projectScreening" },
                            [_vm._v("下次回访时间：")]
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px !important" },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "timestamp",
                            },
                            on: { change: _vm.nextDateTime },
                            model: {
                              value: _vm.nexttime,
                              callback: function ($$v) {
                                _vm.nexttime = $$v
                              },
                              expression: "nexttime",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("下次回访日期")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                      on: {
                        "sort-change": _vm.sortEs,
                        "selection-change": _vm.selsChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3946342523
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "主题", width: "250" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        width: "300",
                                        trigger: "hover",
                                        disabled:
                                          scope.row.content.length <= 20,
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.row.content)),
                                      ]),
                                      _vm._v(" "),
                                      scope.row.content.length <= 20
                                        ? _c(
                                            "span",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [_vm._v(_vm._s(scope.row.content))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.content.length > 20
                                        ? _c(
                                            "span",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.content.substr(
                                                    0,
                                                    20
                                                  ) + "..."
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          151745478
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "客户名称", width: "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#1890FF" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goTodetails(
                                            scope.row,
                                            scope
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.cname)),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3410089491
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "电话号码", width: "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [_vm._v(_vm._s(scope.row.mobile))]
                              },
                            },
                          ],
                          null,
                          false,
                          1667001181
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "类型", width: "250" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [_vm._v(_vm._s(scope.row.type))]
                              },
                            },
                          ],
                          null,
                          false,
                          469485541
                        ),
                      }),
                      _vm._v(" "),
                      _vm.displaySettings.includes("执行人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 5,
                              attrs: {
                                label: "执行人",
                                width: "120",
                                prop: "follow_realname",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("执行人")
                                      },
                                    },
                                  },
                                  [_vm._v("执行人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("下次回访日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 17,
                              attrs: {
                                label: "下次回访日期",
                                sortable: "custom",
                                width: "165",
                                prop: "next_time",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("下次回访日期")
                                      },
                                    },
                                  },
                                  [_vm._v("下次回访日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "跟进日期" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [_vm._v(_vm._s(scope.row.follw_time))]
                              },
                            },
                          ],
                          null,
                          false,
                          2314226121
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "toolbar",
                    staticStyle: { margin: "10px 0px" },
                    attrs: { span: 24 },
                  },
                  [
                    _c("pagination", {
                      attrs: {
                        "current-page": _vm.query.page,
                        totalPage: _vm.query.total,
                        pageSize: _vm.query.pagesize,
                      },
                      on: {
                        handleSizeChange: _vm.handleSizeChange,
                        handleCurrentChange: _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }